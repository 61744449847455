import { getToken } from "../utils/util";
const LOCAL = "local";
const USER = "user";
const PAYMENT = "payment";
const ORDER = "order";
const PRODUCTION = "production";
const state = PRODUCTION;
const MOBILE_THRESHOLD = 640;
const API_ENDPOINT =
  state === PRODUCTION
    ? "https://apis.mysilkcart.com/"
    : "http://localhost:5000/";
const HEADER_TOKEN = {
  headers: {
    "content-type": "application/json",
    token: getToken(),
  },
};
const DEFAULT_BANNER = "/default_test_banner.png";
const PLACEHOLDER_IMAGE = "/placeholder.png";
const IMAGE_UPLOAD_END = API_ENDPOINT + "sam/upload-image";
const IMAGES_GET_END = API_ENDPOINT + "sam/get-images";
const HOSTNAME =
  state === PRODUCTION ? "https://mysilkcart.com/" : "http://localhost:5000/";
const PAGE_SIZE = 30;
const INR = "₹";
const LONG_DASH = "—";
const ADVANCE_PAID = "Advance Paid";
const PAYMENT_COMPLETE = "Payment Complete";
const PENDING = "Pending";
const REJECTED = "Rejected";
const SILKCART_PC = 3.5;
export {
  ORDER,
  PAYMENT,
  USER,
  SILKCART_PC,
  REJECTED,
  PENDING,
  ADVANCE_PAID,
  PAYMENT_COMPLETE,
  LONG_DASH,
  INR,
  PLACEHOLDER_IMAGE,
  PAGE_SIZE,
  API_ENDPOINT,
  HEADER_TOKEN,
  HOSTNAME,
  MOBILE_THRESHOLD,
  IMAGES_GET_END,
  IMAGE_UPLOAD_END,
  DEFAULT_BANNER,
};
