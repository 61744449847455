import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Avatar,
  Modal,
  TextField,
  IconButton,
  Divider,
  Tab,
  Tabs,
  CardMedia,
  Pagination,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Alert,
} from "@mui/material";
import {
  formatCurrency,
  getMultipartHeader,
  toastError,
  useParamsQuery,
} from "../../utils/util";
import BackBtn from "../widgets/BackBtn";
import { API_ENDPOINT, SILKCART_PC } from "../../constants/constants";
import useCommonGetInit from "../hooks/useCommonGetInit";
import MuPb from "../widgets/MuPb";
import { Link, NavLink, useNavigate } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import axios from "axios";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

const SettlementPage = () => {
  const query = useParamsQuery();
  const user_id = query.get("id");
  const amount = Number(query.get("amount")); // Convert the amount to a number
  const [settlementDate, setSettlementDate] = useState(new Date());
  const [tid, setTid] = useState("");
  const [file, setFile] = useState(null);
  const [discount, setDiscount] = useState(0.0);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${API_ENDPOINT}admin/get-user/${user_id}`
  );

  if (!user_id || user_id.trim() === "") {
    return <Alert severity="error">User ID is not valid</Alert>;
  }
  if (isNaN(amount)) {
    return <Alert severity="error">Amount is not a valid number</Alert>;
  }
  if (isLoading) {
    return <MuPb />;
  }
  if (isError || !data?.user) {
    return <Alert severity="error">Error: {error?.message}</Alert>;
  }
  const charge = (amount * (SILKCART_PC / 100)).toFixed(2);
  const total = amount - charge - discount;

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const onSubmit = async () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", user_id);
    formData.append("amount", total);
    formData.append("settlement_data", settlementDate);
    formData.append("transaction_id", tid);
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        `${API_ENDPOINT}admin/upload-admin-receipt`,
        formData,
        getMultipartHeader()
      );
      toast.success(data.message);
      setTimeout(() => {
        navigate(-1);
      }, 3000);
    } catch (error) {
      toastError(error);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <Box>
      <BackBtn />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Card sx={{ maxWidth: 700, margin: "auto", mt: 1, p: 3 }}>
          <Typography
            id="settlement-summary-title"
            variant="h6"
            fontWeight={600}
            component="h2"
            marginBottom={2}
          >
            Settlement Summary
          </Typography>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <Typography>Company Name:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign="right">
                <strong>{data?.user?.company}</strong>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Orders List:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign="right">
                <Link to={`/home/transactions/orders/${user_id}`}>
                  View Orders List
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Silkcart ID:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign="right">
                <Link to={`/home/orders/user/${data?.user?.silkcart_id}`}>
                  {data?.user?.silkcart_id}
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Total Amount:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign="right">
                {formatCurrency(amount)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Discount:</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
                variant="outlined"
                size="small"
                type="number"
                label="Discount Amount(₹)"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>Charge (3.5%):</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign="right">
                {formatCurrency(charge)}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <Typography>Amount to Settle:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign="right">{formatCurrency(total)}</Typography>
            </Grid>
          </Grid>
          <TextField
            fullWidth
            size="small"
            value={tid}
            onChange={(e) => setTid(e.target.value)}
            label="Copy paste Transaction ID here"
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <DateTimePicker
            label="Settlement Date"
            size="small"
            variant="outlined"
            value={dayjs(settlementDate)}
            sx={{
              width: "100%",
              mt: 1,
              "& .MuiInputBase-root": { fontSize: "small" },
              "& .MuiInputLabel-root": { fontSize: "small" },
            }}
            onChange={(newDate) => {
              setSettlementDate(newDate ? newDate.toDate() : new Date());
            }}
          />
          <Button
            style={{ marginTop: "16px" }}
            size="small"
            startIcon={<AttachFileIcon />}
            variant="outlined"
            component="label"
            color="primary"
          >
            Attach Bill Receipt(pdf)
            <input
              type="file"
              hidden
              accept="application/pdf"
              onChange={handleFileChange}
            />
          </Button>
          {file && (
            <Typography
              sx={{ mt: 1, color: "#f26523" }}
              variant="body2"
              color="textSecondary"
            >
              Selected file: {file.name}
            </Typography>
          )}
          <LoadingButton
            loading={submitting}
            onClick={onSubmit}
            sx={{ mt: 2 }}
            fullWidth
            variant="contained"
            color="primary"
          >
            Submit
          </LoadingButton>
        </Card>
      </LocalizationProvider>
    </Box>
  );
};

export default SettlementPage;
