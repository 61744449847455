import React from 'react';
import './timeline.css'
const Timeline = () => {

    return (
        <div className='timeline-container'>
            TimeLines
        </div>
    );
};

export default Timeline;
/*

Order Placed (SSP)

cocoon spinning (ASR)

cocoo ready to be dispatched

since chawki date is calculcated from date of actual release, how can ssp enter chawki date while adding stock?
when asr make purchase, he will enter a chawki date and this date will be matched against start release date(SRD) and end release date(ERD) for which 
technically the stock query is, give me all the stocks where chawki date is within SRD+13days and ERD+13days.

I think for carrying forward with the chawki date mentioned by ASR, the SSP will have to make sure that he releases the eggs 13 days prior to the mentioned chawki date.
So SSP entering chawki date while adding stock is not making sense here.


*/