import React, { useEffect, useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import {
  Alert,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
  Avatar,
  Typography,
} from "@mui/material";
import { API_ENDPOINT } from "../../constants/constants";
import { getNewHeader } from "../../utils/util";

const Support = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    loadSupportRequests();
  }, []);

  function loadSupportRequests() {
    // Replace `${API_ENDPOINT}` with your actual API endpoint
    // Adjust `getNewHeader()` according to your API's requirements
    axios
      .get(`${API_ENDPOINT}admin/get-support-requests`, getNewHeader())
      .then((response) => {
        setRequests(response.data); // Assuming the response data is directly the array of requests
        setLoading(false);
      })
      .catch((err) => {
        setError("Error fetching support requests");
        setLoading(false);
      });
  }

  if (loading) {
    return <Box>Loading...</Box>;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Box sx={{ mt: 4, mx: 2 }}>
      <Typography variant="h4" gutterBottom>
        Support Requests
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User ID</TableCell>
              <TableCell>Topic</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Raised On</TableCell>
              <TableCell>Image</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requests.map((request) => (
              <TableRow key={request._id}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  <Link
                    href={`/home/orders/user/${request.user_id}`}
                    underline="hover"
                  >
                    {request.user_id}
                  </Link>
                </TableCell>
                <TableCell>{request.topic}</TableCell>
                <TableCell>{request.message}</TableCell>
                <TableCell>
                  {format(new Date(request.createdOn), "MMM dd, yyyy  hh:mm a")}
                </TableCell>
                <TableCell>
                  <Avatar
                    src={request.image}
                    alt="Support Request Image"
                    variant="rounded"
                    sx={{ width: 56, height: 56 }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Support;
