import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
const DataInfoCard = ({ title, value, Icon, bgColor, color }) => {
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: bgColor,
          color: color,
          padding: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 2,
          }}
        >
          <Icon fontSize="large" />
        </Box>
        <CardContent
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" component="div" sx={{ textAlign: "center" }}>
            {title}
          </Typography>
          <Typography variant="body1" component="div">
            {value}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default DataInfoCard;
