import React, { useState } from "react";
import { API_ENDPOINT } from "../../constants/constants";
import { toast } from "react-toastify";
import axios from "axios";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { getHeader, getMultipartHeader, toastError } from "../../utils/util";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsList from "./NotificationsList";
const Notifications = () => {
  const ALL = "all";
  const SPECIFIC = "specific";

  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [phone, setPhone] = useState("");
  const [type, setType] = useState(ALL);
  const [ctab, setCtab] = useState(0);
  const [loading, setLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [uploading, setUploading] = useState(false);

  const handleFileChange = async (event) => {
    if (event.target.files.length > 0) {
      const imageFile = event.target.files[0];
      setSelectedFile(event.target.files[0]);
      setUploading(true);
      const fd = new FormData();
      fd.append("image", imageFile);
      setUploading(true);
      try {
        const { data } = await axios.post(
          `${API_ENDPOINT}uploads/upload-image-file`,
          fd,
          getMultipartHeader()
        );
        setImageUrl(data.message);
      } catch (error) {
        toastError(error);
      } finally {
        setUploading(false);
      }
    }
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setImageUrl("");
    setSelectedFile(null);
  };

  const send = () => {
    if (title.trim() === "") {
      toast.error("Please enter title");
      return;
    } else if (body.trim() === "") {
      toast.error("Please enter message");
      return;
    } else if (type === SPECIFIC && phone.trim() === "") {
      toast.error("Please enter silkcart id");
    } else {
      let dataToSubmit = {
        title,
        body,
        type,
        imageUrl,
        silkcart_id: phone,
      };
      setLoading(true);
      axios
        .post(
          `${API_ENDPOINT}admin/send-push-message`,
          dataToSubmit,
          getHeader()
        )
        .then((res) => {
          setLoading(false);
          toast.success("Sent Successfully");
        })
        .catch((error) => {
          setLoading(false);
          toastError(error);
        });
    }
  };
  const handleTabChange = (event, newValue) => {
    setCtab(newValue);
  };
  return (
    <Box>
      <Tabs
        value={ctab}
        onChange={handleTabChange}
        aria-label="chose tabs option"
      >
        <Tab label="Send Nitification" />
        <Tab label="View Account Notifications" />
      </Tabs>
      {ctab === 0 ? (
        <Box>
          <div className="notifications-container">
            <div
              style={{
                maxWidth: "600px",
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "20px",
              }}
            >
              <TextField
                size="small"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
                label="Enter Title"
                type="text"
              />
              <TextField
                sx={{ mt: 2 }}
                multiline
                rows={4}
                size="small"
                value={body}
                onChange={(e) => setBody(e.target.value)}
                fullWidth
                label="Enter Your message"
                type="text"
              />
              <Box mt={2} mb={2}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {" "}
                    Send Notification to
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    label=" Send Notification to"
                    onChange={(e) => setType(e.target.value)}
                  >
                    <MenuItem value={ALL}>To Everyone</MenuItem>
                    <MenuItem value={"ssp"}>To all SSP</MenuItem>
                    <MenuItem value={"asr"}>To all ASR</MenuItem>
                    <MenuItem value={"crc"}>To all CRC</MenuItem>
                    <MenuItem value={"swr"}>To all SWR</MenuItem>
                    <MenuItem value={SPECIFIC}>To Specific User</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {type === SPECIFIC ? (
                <TextField
                  fullWidth
                  size="small"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Enter Silkcart ID"
                  type="text"
                />
              ) : (
                ""
              )}

              <Box display="flex" alignItems="center">
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  type="file"
                  onChange={handleFileChange}
                />

                <label htmlFor="raised-button-file">
                  {uploading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      {imageUrl === "" ? (
                        <IconButton
                          color="primary"
                          aria-label="upload file"
                          component="span"
                        >
                          <AttachFileIcon />
                        </IconButton>
                      ) : (
                        <Box position={"relative"}>
                          <IconButton
                            sx={{
                              position: "absolute",
                              top: -8,
                              right: -16,
                              zIndex: 2,
                            }}
                            size="small"
                            onClick={handleClose}
                          >
                            <CloseIcon style={{ color: "red" }} />
                          </IconButton>
                          <img
                            src={imageUrl}
                            style={{ width: 90, height: 90 }}
                          />
                        </Box>
                      )}
                    </>
                  )}
                  {selectedFile ? selectedFile.name : "Attach image"}
                </label>
              </Box>

              <LoadingButton
                sx={{ mt: 2 }}
                fullWidth
                loading={loading}
                loadingPosition="start"
                onClick={send}
                variant="outlined"
              >
                Send
              </LoadingButton>
            </div>
          </div>
        </Box>
      ) : (
        <NotificationsList />
      )}
    </Box>
  );
};

export default Notifications;
