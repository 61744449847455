import React from 'react';
import './banners.css'
const Banners = () => {
    return (
        <div className='banners-container'>
            Banners
        </div>
    );
};

export default Banners;