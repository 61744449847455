import { Alert, CircularProgress, Grid, LinearProgress } from "@mui/material";
import React from "react";
import DataInfoCard from "./DataInfoCard";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import GroupIcon from "@mui/icons-material/Group";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import useCommonGetInit from "../hooks/useCommonGetInit";
import { API_ENDPOINT } from "../../constants/constants";
import MuPb from "../widgets/MuPb";

const TransactionReportCards = ({ type }) => {
  const {
    data: settlementData,
    isLoading: settlementLoading,
    isError: isSettlementError,
    error: settlementError,
  } = useCommonGetInit(`${API_ENDPOINT}admin/settlement-stats?type=${type}`);
  if (settlementLoading) {
    return <LinearProgress sx={{ height: 8 }} />;
  }
  if (isSettlementError) {
    return <Alert severity="error">Error in loading report</Alert>;
  }

  return (
    <Grid container spacing={3}>
      <DataInfoCard
        title="Total Amounts to Settle"
        value={`₹${settlementData?.toSettle?.amount}`}
        Icon={MonetizationOnIcon}
        bgColor="#fdd835"
        color="#424242"
      />
      <DataInfoCard
        title="Total Users to Settle"
        value={`${settlementData?.toSettle?.usersCount}`}
        Icon={GroupIcon}
        bgColor="#1e88e5"
        color="#FFFFFF"
      />
      <DataInfoCard
        title="Total Amounts Settled"
        value={`₹${settlementData?.settled?.amount}`}
        Icon={CheckCircleIcon}
        bgColor="#43a047"
        color="#FFFFFF"
      />
      <DataInfoCard
        title="Total Users Settled"
        value={`${settlementData?.settled?.usersCount}`}
        Icon={PeopleOutlineIcon}
        bgColor="#e53935"
        color="#FFFFFF"
      />
    </Grid>
  );
};

export default TransactionReportCards;
