import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  Paper,
  List,
  Card,
  CardContent,
  CardMedia,
  Box,
  Modal,
  IconButton,
  Backdrop,
  TextField,
} from "@mui/material";
import {
  ADVANCE_PAID,
  API_ENDPOINT,
  PAYMENT_COMPLETE,
} from "../../constants/constants";
import { Link, useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
  getNewHeader,
  getToken,
  toastError,
  useParamsQuery,
} from "../../utils/util";
import BackBtn from "../widgets/BackBtn";
import SearchBar from "../widgets/searchBar";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { format } from "date-fns";
import FullImageModal from "../widgets/FullImageModal";

function ReceiptDetails() {
  const navigate = useNavigate();
  const { receiptId } = useParams();
  const query = useParamsQuery();
  const rid = query.get("rid");
  const [receipts, setReceipts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [showForm, setShowForm] = useState({
    visible: false,
    id: null,
    orderId: null,
    receiptUrl: null,
    status: null,
  });
  const [formValues, setFormValues] = useState({
    amount: "",
    transactionId: "",
  });

  const formatDate = (dateString) => {
    return format(new Date(dateString), "PPPpp");
  };

  const handleOpen = (url) => {
    setSelectedImage(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    loadReceipts();
  }, [receiptId]);
  function loadReceipts() {
    axios
      .get(
        `${API_ENDPOINT}admin/get-receipts?order_id=${receiptId}`,
        getNewHeader()
      )
      .then((response) => {
        const myReceipts = response.data.receipts;
        const myList = myReceipts.filter((receipt) => receipt._id !== rid);
        setReceipts(myList);
        setLoading(false);
      })
      .catch((err) => {
        setError("Error fetching receipts");
        setLoading(false);
      });
  }

  if (loading)
    return (
      <Container>
        <Typography>Loading...</Typography>
      </Container>
    );
  if (error)
    return (
      <Container>
        <Typography>{error}</Typography>
      </Container>
    );

  const updatePaidStatus = (
    id,
    order_id,
    transactionId,
    current_payment,
    payment_amount
  ) => {
    setUpdating(true);
    axios
      .post(
        `${API_ENDPOINT}admin/update-receipt-status`,
        {
          id,
          order_id,
          transactionId,
          current_payment,
          payment_amount,
        },
        getNewHeader()
      )
      .then((response) => {
        setUpdating(false);
        toast.success("Updated Successfully");
        navigate(-1);
      })
      .catch((error) => {
        setUpdating(false);
        toastError(error);
      });
  };

  return (
    <div>
      <BackBtn />
      <Container maxWidth="lg">
        {" "}
        {/* Adjusted for better spacing */}
        <Paper elevation={3} sx={{ padding: 2, mt: 2, marginBottom: 2 }}>
          <Typography variant="h6" component="div">
            Receipts for Order ID:{" "}
            <span style={{ color: "green" }}>#{receiptId}</span>
          </Typography>

          <List>
            {receipts.map((receipt) => (
              <Box>
                <Typography variant="h6" component="div">
                  This receipt is for :
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    {receipt.type === "cocoon" ? "Cocoon" : "Order"}
                  </span>
                </Typography>
                <Card
                  key={receipt._id}
                  sx={{
                    marginBottom: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={receipt.receipt_url}
                    alt="Receipt"
                    sx={{
                      width: 100,
                      height: 100,
                      cursor: "pointer",
                      margin: 1,
                    }}
                    onClick={() => handleOpen(receipt.receipt_url)}
                  />
                  <CardContent sx={{ flex: "1 0 auto", paddingLeft: 2 }}>
                    <Typography variant="subtitle1" color="text.secondary">
                      User ID:{" "}
                      <Link
                        to={`/home/orders/user/${receipt.user_id}`}
                        style={{ textDecoration: "none", color: "blue" }}
                      >
                        {receipt.user_id}
                      </Link>{" "}
                      {/* Example clickable user ID */}
                    </Typography>
                    <Typography variant="body2">
                      Status: {receipt.status?.toUpperCase()}
                    </Typography>
                    <Typography variant="body2">
                      Amount:{" "}
                      {receipt.payment_amount ? receipt.payment_amount : "NA"}
                    </Typography>
                    <Typography variant="body2">
                      Transaction Id:{" "}
                      {receipt.transactionId ? receipt.transactionId : "NA"}
                    </Typography>
                    <Typography variant="body2">
                      Created On: {formatDate(receipt.createdOn)}{" "}
                      {/* Formatted Date */}
                    </Typography>
                    <Typography variant="body2">
                      Updated On: {formatDate(receipt.updatedOn)}{" "}
                      {/* Formatted Date */}
                    </Typography>
                  </CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      m: 1,
                    }}
                  >
                    {/* <LoadingButton
                      loading={updating}
                      variant="contained"
                      color="primary"
                      disabled={
                        receipt.status === PAYMENT_COMPLETE ||
                        receipt.status === ADVANCE_PAID
                      }
                      onClick={() => {
                        // updatePaidStatus(
                        //   receipt.order_id,
                        //   receipt.current_payment
                        // )
                        setShowForm({
                          visible: true,
                          id: receipt._id,
                          orderId: receipt.order_id,
                          receiptUrl: receipt.receipt_url,
                          status: receipt.status,
                        });
                      }}
                    >
                      {receipt.status === PAYMENT_COMPLETE ||
                      receipt.status === ADVANCE_PAID
                        ? receipt.status
                        : "Approve"}
                    </LoadingButton> */}
                  </Box>
                </Card>
              </Box>
            ))}
          </List>
        </Paper>
        {/* Full-screen Modal for Image Viewing */}
        <FullImageModal
          open={open}
          handleClose={handleClose}
          imgUrl={selectedImage}
        />
        <Modal
          open={showForm.visible}
          closeAfterTransition
          onClose={() => setShowForm({ ...showForm, visible: false })}
          aria-labelledby="payment-modal-title"
          aria-describedby="payment-modal-description"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Backdrop open={open} timeout={500}>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                maxWidth: "600px", // Increased max-width for a bit more space
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2, // Added rounded corners
              }}
            >
              <Typography
                id="payment-modal-title"
                variant="h6"
                component="h2"
                sx={{ mb: 3 }}
              >
                Payment Details
              </Typography>
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  fullWidth
                  label="Amount"
                  variant="outlined"
                  value={formValues.amount}
                  onChange={(e) =>
                    setFormValues({ ...formValues, amount: e.target.value })
                  }
                  sx={{ mb: 2 }} // Increased margin between fields
                />
                <TextField
                  fullWidth
                  label="Transaction ID"
                  variant="outlined"
                  value={formValues.transactionId}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      transactionId: e.target.value,
                    })
                  }
                  sx={{ mb: 3 }} // Increased margin for more space before the buttons
                />
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr", // Make buttons stack vertically
                    gap: 2, // Added gap between buttons
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    loading={updating}
                    disabled={showForm.status === ADVANCE_PAID}
                    color="primary"
                    onClick={() => {
                      updatePaidStatus(
                        showForm.id,
                        showForm.orderId,
                        formValues.transactionId,
                        ADVANCE_PAID,
                        formValues.amount
                      );
                    }}
                    sx={{ gridColumn: "1 / -1" }}
                  >
                    Mark as Advance Payment
                  </LoadingButton>
                  <LoadingButton
                    disabled={showForm.status === PAYMENT_COMPLETE}
                    variant="contained"
                    loading={updating}
                    color="secondary"
                    onClick={() => {
                      updatePaidStatus(
                        showForm.id,
                        showForm.orderId,
                        formValues.transactionId,
                        PAYMENT_COMPLETE,
                        formValues.amount
                      );
                    }}
                    sx={{ gridColumn: "1 / -1" }}
                  >
                    Mark as Full Payment
                  </LoadingButton>
                </Box>
              </Box>
              <IconButton
                aria-label="close"
                onClick={() => setShowForm({ ...showForm, visible: false })}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Backdrop>
        </Modal>
      </Container>
    </div>
  );
}
export default ReceiptDetails;
