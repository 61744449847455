import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Link,
  Divider,
  Alert,
} from "@mui/material";
import dayjs from "dayjs"; // Ensure dayjs is installed
import { API_ENDPOINT } from "../../constants/constants";
import {
  getErrorResponse,
  getHeader,
  getToken,
  toastError,
} from "../../utils/util";
import BackBtn from "../widgets/BackBtn";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import CocoonDetails from "./CocoonDetails";
import SimpleYesNoDialog from "../widgets/SimpleYesNoDialog";
import MuPb from "../widgets/MuPb";

function OrderDetails() {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [triggering, setTriggering] = useState(false);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await axios.get(
          `${API_ENDPOINT}admin/get-order/${orderId}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`, // Ensure getToken() is correctly implemented
            },
          }
        );
        setOrder(response.data.order);
      } catch (err) {
        setError(getErrorResponse(err));
        toastError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchOrder();
  }, [orderId]);

  if (error) return <Typography color="error">{error}</Typography>;
  if (loading) return <MuPb />;
  if (!order)
    return (
      <Typography color="error">
        Order not found. It is either moved/deleted or request is invalid
      </Typography>
    );

  const formatDate = (dateString) => {
    if (!dateString) return "Empty";
    return dayjs(dateString).format("MMMM D, YYYY h:mm A");
  };
  const updateCocoonStock = async () => {
    try {
      setTriggering(true);
      const dataToSubmit = {
        order_id: orderId,
      };
      const res = await axios.post(
        `${API_ENDPOINT}admin/trigger-asr-ssp-negotiation`,
        dataToSubmit,
        getHeader()
      );
      toast.success(res.data.message);
      setTimeout(() => {
        document.location.reload();
      }, 700);
    } catch (error) {
      toastError(error);
    } finally {
      setTriggering(false);
    }
  };

  const handleDelteOrder = () => {
    setShowDeleteDialog(true);
  };
  const onYesDelete = async () => {
    try {
      setDeleting(true);
      const { data } = await axios.delete(
        `${API_ENDPOINT}admin/delete-order?order_id=${order?.order_id}`,
        getHeader()
      );
      navigate(-1);
    } catch (error) {
      toastError(error);
    } finally {
      setDeleting(false);
    }
  };

  const renderDetail = (label, value) => (
    <Grid item xs={12} sm={6} md={4}>
      <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
        {label}
      </Typography>
      <Typography variant="body1">{value || "NA"}</Typography>
    </Grid>
  );

  const renderGroupHeader = (title) => (
    <Grid item xs={12}>
      <Box sx={{ bgcolor: "grey.300", p: 1 }}>
        <Typography fontSize={"20px"} fontWeight={"bold"}>
          {title}
        </Typography>
      </Box>
    </Grid>
  );

  return (
    <Box p={3}>
      <SimpleYesNoDialog
        title={"Please confirm!"}
        message={"Are you sure you want to delete this Order?"}
        show={showDeleteDialog}
        setShow={setShowDeleteDialog}
        onYes={onYesDelete}
      />
      <BackBtn />
      <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
        <Typography variant="h4" gutterBottom>
          Order Details
        </Typography>
        <Grid container spacing={3}>
          <Box mt={2} ml={3} sx={{ p: 1 }}>
            <Typography fontWeight={"bold"}>
              {" "}
              Total Amount:
              <span style={{ color: "green", marginLeft: "8px" }}>
                ₹
                {Math.round(
                  (order?.price * order?.quantity_required) / 100 +
                    (order?.blackbox_charge ?? 0) +
                    (order?.shipping_charge ?? 0)
                )}
              </span>
            </Typography>
          </Box>
          {renderGroupHeader("Dates")}
          {renderDetail("Chaki Date", formatDate(order.chawki_date))}
          {renderDetail("Created On", formatDate(order.createdOn))}
          {renderDetail("Updated On", formatDate(order.updatedOn))}

          {renderGroupHeader("Payments & Delivery")}
          {renderDetail("First Payment", order.first_payment)}
          {renderDetail("Second Payment", order.second_payment)}
          {renderDetail("Delivery Status", order.delivery_status)}

          {renderGroupHeader("Order Specifics")}
          {renderDetail("Order ID", order.order_id)}
          {renderDetail("Price", `₹${order.price}`)}
          {renderDetail("Seed Type", order.seed_type)}
          {renderDetail("Blackbox Required", order.blackbox_required)}
          {renderDetail("Order Type", order.order_type)}
          {renderDetail("Quantity Required", order.quantity_required)}
          {renderDetail("Supplier ID", order.supplier_id)}
          {renderDetail("Shipping Required", order.shipping_required)}

          {renderGroupHeader("Additional Charges")}
          {renderDetail("Blackbox Charge", `₹${order.blackbox_charge || "0"}`)}
          {renderDetail("Shipping Charge", `₹${order.shipping_charge || "0"}`)}

          {order.lots && order.lots.length > 0 && renderGroupHeader("Lots")}
          {order.lots &&
            order.lots.map((lot, index) => (
              <React.Fragment key={index}>
                {renderDetail(`Lot #${index + 1} Number`, lot.lot_no)}
                {renderDetail(`Lot #${index + 1} Quantity`, lot.quantity)}
              </React.Fragment>
            ))}
          {order.bill_link && (
            <>
              {renderGroupHeader("Bill")}
              <Grid item xs={12}>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Bill Link
                </Typography>
                <Link href={order.bill_link} target="_blank" rel="noopener">
                  View Bill
                </Link>
              </Grid>
            </>
          )}
          <>
            {renderGroupHeader("Cocoon Specifics")}
            <Grid item xs={12}>
              <CocoonDetails order={order} />
            </Grid>
          </>
        </Grid>
        {order?.status === "Indent Received" && (
          <LoadingButton
            sx={{ mt: 2 }}
            loading={deleting}
            size="small"
            color="warning"
            variant="contained"
            onClick={handleDelteOrder}
          >
            Delete Order
          </LoadingButton>
        )}
      </Paper>
    </Box>
  );
}

export default OrderDetails;
