import MenuIcon from '@mui/icons-material/Menu';
import ListIcon from '@mui/icons-material/List';
import { useState } from 'react';
import './topMenuIcon.css'
const TopMenuIcon = (props)=>{
    const {collapse, setCollapse} = props
    const onClick = (event)=>{
        setCollapse(!collapse)
    }
    return(
        <div onClick={onClick} className='top_menu_icon'>
            {collapse?<ListIcon />:<MenuIcon />}
        </div>
    )
}
export default TopMenuIcon