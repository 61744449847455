import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  IconButton,
  Button,
  Pagination,
  Stack,
} from "@mui/material";
import { styled } from "@mui/system";
import { API_ENDPOINT, PAGE_SIZE, PENDING } from "../../constants/constants";
import PreviewIcon from "@mui/icons-material/Preview";
import { Link } from "react-router-dom";
import useCommonGetInit from "../hooks/useCommonGetInit";
import CustomCircularP from "../widgets/customCircularP";

const StyledPaper = styled(Paper)({
  padding: "16px",
  marginBottom: "16px",
});

const StyledTableCell = styled(TableCell)({
  fontSize: "16px",
  padding: "8px 16px",
});

const OrdersList = () => {
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [orderId, setOrderId] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");

  const {
    data: ordersData,
    isLoading: ordersLoading,
    isError: isOrderError,
    error: orderError,
  } = useCommonGetInit(
    `${API_ENDPOINT}admin/get-orders?page=${page}&type=${type}&order_id=${orderId}&status=${status}`
  );

  const ASR_TO_SSP = "asr_to_ssp";
  const CRC_TO_SSP = "crc_to_ssp";
  const SWR_TO_CRC = "swr_to_crc";
  const pageChange = (e, page) => {
    setPage(page);
  };
  return (
    <StyledPaper elevation={3}>
      <CustomCircularP show={ordersLoading} />
      <Typography variant="h4" gutterBottom component="div">
        All Orders
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Stack direction={"row"} gap={2}>
          <FormControl
            size="small"
            variant="outlined"
            margin="normal"
            sx={{ minWidth: 140 }}
          >
            <InputLabel id="type-label">Filter by Type</InputLabel>
            <Select
              labelId="type-label"
              value={type}
              onChange={(e) => setType(e.target.value)}
              label="Filter by Type"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={ASR_TO_SSP}>ASR to SSP</MenuItem>
              <MenuItem value={CRC_TO_SSP}>CRC to SSP</MenuItem>
              <MenuItem value={SWR_TO_CRC}>SWR to CRC</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            size="small"
            variant="outlined"
            margin="normal"
            sx={{ minWidth: 176 }}
          >
            <InputLabel id="type-label">Filter by Status</InputLabel>
            <Select
              labelId="type-label"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              label="Filter by Status"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"Indent Received"}>Indent Received</MenuItem>
              <MenuItem value={"Indent Accepted"}>Indent Accepted</MenuItem>
              <MenuItem value={"Indent Processed"}>Indent Processed</MenuItem>
              <MenuItem value={"Indent Rejected"}>Indent Rejected</MenuItem>
            </Select>
          </FormControl>
        </Stack>

        <TextField
          size="small"
          variant="outlined"
          margin="normal"
          placeholder="Search by Order ID"
          value={orderId}
          onChange={(e) => setOrderId(e.target.value)}
        />
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>S. No.</StyledTableCell>
              <StyledTableCell>Order ID</StyledTableCell>

              <StyledTableCell>Quantity Required</StyledTableCell>
              <StyledTableCell>Buyer ID</StyledTableCell>
              <StyledTableCell>Supplier ID</StyledTableCell>
              <StyledTableCell>Order Status</StyledTableCell>
              <StyledTableCell>Payment Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ordersData?.data?.map((order, index) => (
              <TableRow key={order.order_id}>
                <StyledTableCell align="center">{index + 1}</StyledTableCell>{" "}
                {/* Added Serial Number Value */}
                <StyledTableCell component="th" scope="row">
                  {order.order_id}{" "}
                  <Link to={`order/${order.order_id}`}>
                    <IconButton size="small">
                      <PreviewIcon />{" "}
                    </IconButton>
                  </Link>
                </StyledTableCell>
                <StyledTableCell align="right">
                  {order.quantity_required}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {order.user_id}{" "}
                  <Link to={`user/${order.user_id}`}>
                    <IconButton size="small">
                      <PreviewIcon />{" "}
                    </IconButton>
                  </Link>{" "}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {order.supplier_id}{" "}
                  <Link to={`user/${order.supplier_id}`}>
                    <IconButton size="small">
                      <PreviewIcon />
                    </IconButton>
                  </Link>
                </StyledTableCell>
                <StyledTableCell align="right">{order.status}</StyledTableCell>
                <StyledTableCell align="right">
                  {/* <div>
                    <label>First: </label>
                    <strong>
                      {order.first_payment ? (
                        <span>{order.first_payment}</span>
                      ) : (
                        "Pending"
                      )}
                    </strong>
                  </div>
                  <div>
                    <label>Second: </label>
                    <strong>
                      {order.second_payment ? (
                        <span>{order.second_payment}</span>
                      ) : (
                        "Pending"
                      )}
                    </strong>
                  </div>
                  <div>
                    {(order.first_payment === "processing" ||
                      order.second_payment === "processing") && (
                      <Link to={`receipt/${order.order_id}`}>
                        <Button sx={{ mt: 1 }} size="small" variant="outlined">
                          Check Receipt
                        </Button>
                      </Link>
                    )}
                  </div> */}
                  <div>
                    {order.payment_status
                      ? order.payment_status?.toUpperCase()
                      : PENDING?.toUpperCase()}
                  </div>
                  {/* <Link to={`receipt/${order.order_id}`}>
                    <Button sx={{ mt: 1 }} size="small" variant="outlined">
                      Check Receipt
                    </Button>
                  </Link> */}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2} mb={2} display={"flex"} justifyContent={"center"}>
        <Pagination
          page={page}
          onChange={pageChange}
          count={Math.ceil(ordersData?.totalItems / PAGE_SIZE)}
        />
      </Box>
    </StyledPaper>
  );
};

export default OrdersList;
