import React, { useRef, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CardMedia,
  Pagination,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Grid,
} from "@mui/material";
import useCommonGetInit from "../hooks/useCommonGetInit";
import { API_ENDPOINT, PAGE_SIZE } from "../../constants/constants";
import { Link, NavLink } from "react-router-dom";
import FullImageModal from "../widgets/FullImageModal";
import MuPb from "../widgets/MuPb";
import SearchIcon from "@mui/icons-material/Search";
import { checkFileType } from "../../utils/util";

const ReceiptsList = ({ type }) => {
  const [page, setPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState("");
  const [imgOpen, setImgOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [orderId, setOrderId] = useState("");

  const {
    data: receiptsData,
    isLoading: receiptsLoading,
    isError: isReceiptError,
    error: receiptError,
  } = useCommonGetInit(
    `${API_ENDPOINT}admin/get-payment-receipts?page=${page}&status=${status}&type=${type}`
  );
  const handleImageOpen = (url) => {
    setSelectedImage(url);
    setImgOpen(true);
  };
  const handleImageClose = () => {
    setImgOpen(false);
  };
  if (receiptsLoading) {
    return <MuPb />;
  }
  const handleSearch = (e) => {
    console.log("vl: ", orderId);
  };
  if (receiptError) {
    <Alert severity="error">Error: ${receiptError.message}</Alert>;
  }
  return (
    <Box sx={{ mt: 2 }}>
      <TableContainer component={Paper}>
        <Box p={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6} lg={4}>
              <Typography variant="h6" component="div">
                Payment Receipts
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl size="small" variant="outlined" fullWidth>
                <InputLabel id="type-label">Filter by Status</InputLabel>
                <Select
                  labelId="type-label"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  label="Filter by Status"
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="Advance Paid">Advance Paid</MenuItem>
                  <MenuItem value="Payment Complete">Payment Complete</MenuItem>
                  <MenuItem value="Rejected">Rejected</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} lg={5}>
              <TextField
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
                size="small"
                variant="outlined"
                label="Search by Order ID"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSearch}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Transaction ID</TableCell>
              <TableCell>User ID</TableCell>
              <TableCell>Supplier ID</TableCell>
              <TableCell>Order ID</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Receipt</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Payment Amount</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {receiptsData?.data?.map((item) => (
              <TableRow key={item._id}>
                <TableCell>{item.transactionId}</TableCell>
                <TableCell>{item.user_id}</TableCell>
                <TableCell>{item.supplier_id}</TableCell>
                <TableCell>{item.order_id}</TableCell>
                <TableCell>{item.type}</TableCell>
                <TableCell>
                  {checkFileType(item.receipt_url) === "image" ? (
                    <CardMedia
                      component="img"
                      image={item.receipt_url}
                      alt="Receipt"
                      sx={{
                        width: 100,
                        height: 100,
                        cursor: "pointer",
                      }}
                      onClick={() => handleImageOpen(item.receipt_url)}
                    />
                  ) : (
                    <a
                      href={item.receipt_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Receipt
                    </a>
                  )}
                </TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>{item.payment_amount}</TableCell>
                <TableCell>
                  {new Date(item.createdOn).toLocaleString()}
                </TableCell>
                <TableCell>
                  <NavLink to={`/home/transactions/${item._id}`}>
                    Details
                  </NavLink>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2} mb={2} display={"flex"} justifyContent={"center"}>
        <Pagination
          page={page}
          onChange={() => (e, page) => {
            setPage(page);
          }}
          count={Math.ceil(receiptsData?.totalItems / PAGE_SIZE)}
        />
      </Box>
      <FullImageModal
        open={imgOpen}
        handleClose={handleImageClose}
        imgUrl={selectedImage}
      />
    </Box>
  );
};

export default ReceiptsList;
