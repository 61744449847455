import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { API_ENDPOINT } from "../../constants/constants";
import { getToken } from "../../utils/util";
import BackBtn from "../widgets/BackBtn";

function UserDetails() {
  const { userId } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    axios
      .get(`${API_ENDPOINT}admin/get-user/${userId}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`, // Make sure to define or import getToken function accordingly
        },
      })
      .then((response) => setUser(response.data.user))
      .catch((error) => console.error("Error fetching user:", error));
  }, [userId]);

  if (!user) return <Typography>Loading...</Typography>;

  const detailGroups = {
    personalDetails: {
      title: "Personal Details",
      details: [
        ["Language", user.lan],
        ["Name", user.name],
        ["User Type", user.user_type],
        ["Building", user.building],
        ["Area", user.area],
        ["Taluk", user.taluk],
        ["District", user.district],
        ["State", user.state],
        ["PIN", user.pin],
        ["Phone", user.phone],
        ["Landline", user.landline],
        ["Email", user.email],
      ],
    },
    idProofs: {
      title: "Uploaded ID proofs",
      details: [["Document Type", user.doc_type]],
      customDetail: (
        <Grid item xs={12}>
          <Typography variant="subtitle1" fontWeight="bold">
            ID Proof
          </Typography>
          <img
            src={user.doc_url}
            alt="Document"
            style={{ maxHeight: "200px", maxWidth: "100%" }}
          />
          <Divider />
          <Box mt={2}>
            <Typography variant="subtitle1" fontWeight="bold">
              CSB Licence certificate
            </Typography>
            <img
              src={user.licence_url}
              alt="Document"
              style={{ maxHeight: "200px", maxWidth: "100%" }}
            />
          </Box>
        </Grid>
      ),
    },
    companySpecifics: {
      title: "Company Specifics",
      details: [
        ["Company", user.company],
        ["Licence Number", user.licence_number],
        ["GSTIN", user.gstin],
        ...(user.user_type !== "ssp"
          ? [["Mulberry Area(in acres)", user.mulberry_area]]
          : []),
        ...(user.user_type === "swr" || user.user_type === "ssp"
          ? []
          : [["Source of Eggs", user.egg_source]]),

        ...(user.user_type !== "asr"
          ? [
              [
                user.user_type === "swr"
                  ? "Cocoons yield per year"
                  : "DFLS per Year",
                user.dfls_per_year,
              ],
            ]
          : []),
        ...(user.user_type === "swr" ? [["CRC", user.crc]] : []),
        ...(user.user_type === "swr"
          ? [["Cocoons Market", user.cocoons_market]]
          : []),
        ["Year of establishment", user.yoe ?? ""],
        ["Seed Type", user.seed_type],
      ],
    },
    bankDetails: {
      title: "Bank Details",
      details: [
        ["Bank Name", user.bank?.name],
        ["Beneficiary", user.bank?.beneficiary],
        ["Account No.", user.bank?.account_no],
        ["IFSC", user.bank?.ifsc],
        ["Account Type", user.bank?.account_type],
      ],
    },
  };

  return (
    <Box p={3}>
      <BackBtn />
      <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
        <Typography variant="h5" gutterBottom fontWeight="bold">
          User Details
        </Typography>
        <Avatar
          src={user.profile_pic}
          alt={`${user.name || "User"}'s profile`}
          sx={{ width: 120, height: 120, mb: 2 }}
        />
        <Typography fontSize={"20px"} mb={2} fontWeight={"bold"}>
          {user.name}
        </Typography>
        {Object.entries(detailGroups).map(([key, group]) => (
          <Accordion key={key}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">{group.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {group.details.map(([label, value], index) => (
                  <React.Fragment key={`${key}-${index}`}>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {label}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1">{value}</Typography>
                    </Grid>
                  </React.Fragment>
                ))}
                {group.customDetail}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Paper>
    </Box>
  );
}

export default UserDetails;
