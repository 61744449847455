import React from "react";
import useCommonGetInit from "../hooks/useCommonGetInit";
import { API_ENDPOINT } from "../../constants/constants";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  Typography,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";

const NotificationsList = () => {
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${API_ENDPOINT}admin/get-account-notifications`
  );

  if (isLoading) {
    return <LinearProgress />;
  }

  if (isError) {
    return <Typography color="error">{error.message}</Typography>;
  }

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 600,
        bgcolor: "background.paper",
        margin: "auto",
      }}
    >
      <List>
        {data.map((notification, index) => (
          <React.Fragment key={notification._id}>
            <ListItem
              sx={{ bgcolor: notification.seen ? "grey.200" : "grey.100" }}
              alignItems="flex-start"
            >
              <ListItemText
                primary={notification.message}
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      Sent: {new Date(notification.createdOn).toLocaleString()}
                    </Typography>
                    <br />
                    <Link to={`/home/users/${notification.target_user_id}`}>
                      {`User ID: ${notification.target_user_id}`}
                    </Link>
                  </>
                }
              />
            </ListItem>
            {index < data.length - 1 && <Divider component="li" />}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default NotificationsList;
