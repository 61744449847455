import React from "react";
import { TableCell, Paper, Box } from "@mui/material";
import { styled } from "@mui/system";
import { Route, Routes } from "react-router-dom";
import OrdersList from "./OrdersList";
import OrderDetails from "./OrderDetails";
import ReceiptDetails from "./ReceiptDetails";
import UserDetails from "./UserDetails";

const Orders = () => {
  return (
    <Box>
      <Routes>
        <Route path="" element={<OrdersList />} />
        <Route path="order/:orderId" element={<OrderDetails />} />
        <Route path="user/:userId" element={<UserDetails />} />
        <Route path="receipt/:receiptId" element={<ReceiptDetails />} />
      </Routes>
    </Box>
  );
};

export default Orders;
