import React from "react";
import { Route, Routes } from "react-router-dom";
import TransactionsList from "./TransactionsList";
import TransactionDetails from "./TransactionDetails";
import SettlementPage from "./SettlementPage";
import TransactionOrders from "./TransactionOrders";
const transactions = () => {
  return (
    <div>
      <Routes>
        <Route path="" element={<TransactionsList />} />
        <Route path="/:id" element={<TransactionDetails />} />
        <Route path="/orders/:id" element={<TransactionOrders />} />
        <Route path="/settle" element={<SettlementPage />} />
      </Routes>
    </div>
  );
};

export default transactions;
