import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Tabs,
  Tab,
  Grid,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import useCommonGetInit from "../hooks/useCommonGetInit";
import { API_ENDPOINT } from "../../constants/constants";
import { getHeader, toastError } from "../../utils/util";
import axios from "axios";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import CustomCircularP from "../widgets/customCircularP";

export default function SettingsPage() {
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${API_ENDPOINT}admin/get-user-price`
  );
  const [value, setValue] = useState(0);
  const [updating, setUpdating] = useState(false);
  // SSP Prices State
  const [sspBvPrice, setSspBvPrice] = useState("");
  const [sspCbPrice, setSspCbPrice] = useState("");
  const [sspPiercedCocoonPrice, setSspPiercedCocoonPrice] = useState("");
  const [sspLimitValueBv, setSspLimitValueBv] = useState("");
  const [sspLimitValueCb, setSspLimitValueCb] = useState("");
  const [sspLimitValuePc, setSspLimitValuePc] = useState("");

  // SSP Prices Error State
  const [sspBvPriceError, setSspBvPriceError] = useState("");
  const [sspCbPriceError, setSspCbPriceError] = useState("");
  const [sspPiercedCocoonPriceError, setSspPiercedCocoonPriceError] =
    useState("");
  const [sspLimitValueBvError, setSspLimitValueBvError] = useState("");
  const [sspLimitValueCbError, setSspLimitValueCbError] = useState("");
  const [sspLimitValuePcError, setSspLimitValuePcError] = useState("");

  // CRC Prices State
  const [crcBvPrice, setCrcBvPrice] = useState("");
  const [crcCbPrice, setCrcCbPrice] = useState("");
  const [crcPiercedCocoonPrice, setCrcPiercedCocoonPrice] = useState("");
  const [crcLimitValueBv, setCrcLimitValueBv] = useState("");
  const [crcLimitValueCb, setCrcLimitValueCb] = useState("");
  const [crcLimitValuePc, setCrcLimitValuePc] = useState("");

  // CRC Prices Error State
  const [crcBvPriceError, setCrcBvPriceError] = useState("");
  const [crcCbPriceError, setCrcCbPriceError] = useState("");
  const [crcPiercedCocoonPriceError, setCrcPiercedCocoonPriceError] =
    useState("");
  const [crcLimitValueBvError, setCrcLimitValueBvError] = useState("");
  const [crcLimitValueCbError, setCrcLimitValueCbError] = useState("");
  const [crcLimitValuePcError, setCrcLimitValuePcError] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (data) {
      if (data.sspPrice) {
        setSspBvPrice(data.sspPrice.sspBvPrice);
        setSspCbPrice(data.sspPrice.sspCbPrice);
        setSspPiercedCocoonPrice(data.sspPrice.sspPiercedCocoonPrice);
        setSspLimitValueBv(data.sspPrice.sspLimitValueBv);
        setSspLimitValueCb(data.sspPrice.sspLimitValueCb);
        setSspLimitValuePc(data.sspPrice.sspLimitValuePc);
      }
      if (data.crcPrice) {
        setCrcBvPrice(data.crcPrice.crcBvPrice);
        setCrcCbPrice(data.crcPrice.crcCbPrice);
        setCrcPiercedCocoonPrice(data.crcPrice.crcPiercedCocoonPrice);
        setCrcLimitValueBv(data.crcPrice.crcLimitValueBv);
        setCrcLimitValueCb(data.crcPrice.crcLimitValueCb);
        setCrcLimitValuePc(data.crcPrice.crcLimitValuePc);
      }
    }
  }, [data]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const isFieldValid = (value) => {
      return value && !isNaN(value) && parseFloat(value) >= 0;
    };

    const errors = {};
    const fields =
      value === 0
        ? {
            sspBvPrice: [sspBvPrice, setSspBvPriceError],
            sspCbPrice: [sspCbPrice, setSspCbPriceError],
            sspPiercedCocoonPrice: [
              sspPiercedCocoonPrice,
              setSspPiercedCocoonPriceError,
            ],
            sspLimitValueBv: [sspLimitValueBv, setSspLimitValueBvError],
            sspLimitValueCb: [sspLimitValueCb, setSspLimitValueCbError],
            sspLimitValuePc: [sspLimitValuePc, setSspLimitValuePcError],
          }
        : {
            crcBvPrice: [crcBvPrice, setCrcBvPriceError],
            crcCbPrice: [crcCbPrice, setCrcCbPriceError],
            crcPiercedCocoonPrice: [
              crcPiercedCocoonPrice,
              setCrcPiercedCocoonPriceError,
            ],
            crcLimitValueBv: [crcLimitValueBv, setCrcLimitValueBvError],
            crcLimitValueCb: [crcLimitValueCb, setCrcLimitValueCbError],
            crcLimitValuePc: [crcLimitValuePc, setCrcLimitValuePcError],
          };

    Object.keys(fields).forEach((key) => {
      const [fieldValue, setError] = fields[key];
      if (!isFieldValid(fieldValue)) {
        errors[key] = `Invalid value`;
        setError("Invalid value");
      } else {
        setError(""); // Clear previous errors
      }
    });

    if (Object.keys(errors).length === 0) {
      const dataToSubmit = Object.fromEntries(
        Object.keys(fields).map((key) => [key, parseFloat(fields[key][0])])
      );
      console.log(dataToSubmit);
      updatePrice(value === 0 ? "sspPrice" : "crcPrice", dataToSubmit); // Uncomment when updatePrice function is implemented
    }
  };
  async function updatePrice(userType, priceObject) {
    try {
      const res = await axios.post(
        `${API_ENDPOINT}admin/update-user-price`,
        { userType, priceObject },
        getHeader()
      );
      toast.success(res.data.message);
    } catch (error) {
      toastError(error);
    }
  }

  return (
    <Container maxWidth="md">
      <CustomCircularP show={isLoading} />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label={<strong>SSP Price</strong>} {...a11yProps(0)} />
          <Tab label={<strong>CRC Price</strong>} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {createFormFields(
              sspBvPrice,
              setSspBvPrice,
              sspLimitValueBv,
              setSspLimitValueBv,
              "BV Price",
              sspBvPriceError,
              sspLimitValueBvError
            )}
            {createFormFields(
              sspCbPrice,
              setSspCbPrice,
              sspLimitValueCb,
              setSspLimitValueCb,
              "CB Price",
              sspCbPriceError,
              sspLimitValueCbError
            )}
            {createFormFields(
              sspPiercedCocoonPrice,
              setSspPiercedCocoonPrice,
              sspLimitValuePc,
              setSspLimitValuePc,
              "Pierced Cocoon Price",
              sspPiercedCocoonPriceError,
              sspLimitValuePcError
            )}
          </Grid>
          <Box display={"flex"} justifyContent={"end"}>
            <LoadingButton
              loading={value === 0 && updating}
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Submit
            </LoadingButton>
          </Box>
        </form>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {createFormFields(
              crcBvPrice,
              setCrcBvPrice,
              crcLimitValueBv,
              setCrcLimitValueBv,
              "BV Price",
              crcBvPriceError,
              crcLimitValueBvError
            )}
            {createFormFields(
              crcCbPrice,
              setCrcCbPrice,
              crcLimitValueCb,
              setCrcLimitValueCb,
              "CB Price",
              crcCbPriceError,
              crcLimitValueCbError
            )}
            {createFormFields(
              crcPiercedCocoonPrice,
              setCrcPiercedCocoonPrice,
              crcLimitValuePc,
              setCrcLimitValuePc,
              "Pierced Cocoon Price",
              crcPiercedCocoonPriceError,
              crcLimitValuePcError
            )}
          </Grid>
          <Box display={"flex"} justifyContent={"end"}>
            <LoadingButton
              loading={value === 0 && updating}
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Submit
            </LoadingButton>
          </Box>
        </form>
      </TabPanel>
    </Container>
  );
}

function createFormFields(
  value,
  setValue,
  limitValue,
  setLimitValue,
  label,
  error,
  limitError
) {
  return (
    <>
      <Grid item xs={6}>
        <TextField
          value={value}
          onChange={(e) => setValue(e.target.value)}
          margin="normal"
          fullWidth
          size="small"
          label={label}
          variant="outlined"
          error={!!error}
          helperText={error}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          value={limitValue}
          onChange={(e) => setLimitValue(e.target.value)}
          margin="normal"
          fullWidth
          size="small"
          label="Limit Value"
          variant="outlined"
          error={!!limitError}
          helperText={limitError}
        />
      </Grid>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
