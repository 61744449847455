import './leftMenuLogo.css'
// import appLogo from '../assets/images/applogo.png'
import appIcon from '../assets/svgs/appIcon.svg'
import appLogo from '../assets/images/app_logo.png'
const LeftMenuLogo = (props)=>{
    return(
        <div style={{display: 'flex', height: 60}}>
       {props.collapse?<img className='left-menu-logo' style={{height: '40px'}} src={appLogo} alt="App Icon"/>:<img className='left-menu-logo'  src={appLogo} alt="App Logo"/>}
        </div>
    )
}
export default LeftMenuLogo