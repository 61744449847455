import React from "react";
import BackBtn from "../widgets/BackBtn";
import { Alert, Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import useCommonGetInit from "../hooks/useCommonGetInit";
import { API_ENDPOINT } from "../../constants/constants";
import MuPb from "../widgets/MuPb";

const TransactionOrders = () => {
  const { id } = useParams();
  const {
    data: receiptsData,
    isLoading: receiptsLoading,
    isError: isReceiptError,
    error: receiptError,
  } = useCommonGetInit(
    `${API_ENDPOINT}admin/get-settlement-list-supplier?supplier_id=${id}`
  );

  if (receiptsLoading) {
    return <MuPb />;
  }
  if (isReceiptError) {
    return <Alert severity="error">{receiptError?.message}</Alert>;
  }

  return (
    <Box>
      <BackBtn />
      <Box mb={2}>
        <Typography variant="h4">Transaction Orders</Typography>
      </Box>
      <Grid container spacing={2}>
        {receiptsData?.map((receipt) => (
          <Grid item xs={12} sm={6} md={4} key={receipt._id}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Order ID:{" "}
                  <a href={`/home/orders/order/${receipt.order_id}`}>
                    {receipt.order_id}
                  </a>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Status: {receipt.status}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Payment Amount: ₹{receipt.payment_amount}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Transaction ID: {receipt.transactionId}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Created On: {new Date(receipt.createdOn).toLocaleString()}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Updated On: {new Date(receipt.updatedOn).toLocaleString()}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TransactionOrders;
