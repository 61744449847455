import React from "react";
import { Modal, Backdrop, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const FullImageModal = ({ open, handleClose, imgUrl }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Backdrop open={open} timeout={500}>
        <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
          <img
            src={imgUrl}
            alt="Full-Screen Receipt"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 16,
              top: 16,
              color: "white",
              backgroundColor: "black",
              "&:hover": { backgroundColor: "grey" },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Backdrop>
    </Modal>
  );
};

export default FullImageModal;
