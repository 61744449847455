import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { API_ENDPOINT, PAGE_SIZE } from "../../constants/constants";
import { getDateFormat, getHeader } from "../../utils/util";
import CustomCircularP from "../widgets/customCircularP";
import SearchBar from "../widgets/searchBar";
import "./customers.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import PreviewIcon from "@mui/icons-material/Preview";
export default function Customers() {
  const [nameFilter, setNameFilter] = useState("");
  const [phoneFilter, setPhoneFilter] = useState("");
  const [deviceFilter, setDeviceFilter] = useState("");
  const [subscriptionFilter, setSubscriptionFilter] = useState("");
  const [hubFilter, setHubFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [customers, setCustomers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sp, setSp] = useState("");

  const updateCustomer = (silkcart_id, newValues) => {
    const updatedCustomers = customers.map((customer) => {
      if (customer.silkcart_id === silkcart_id) {
        // return a new object with the existing data and the new data
        return { ...customer, ...newValues };
      } else {
        // return the existing item unchanged
        return customer;
      }
    });

    setCustomers(updatedCustomers);
  };
  useEffect(() => {
    getCustomers();
    getTotalCustomers();
  }, [subscriptionFilter, nameFilter, phoneFilter]);
  function getCustomers() {
    setLoading(true);
    axios
      .post(
        API_ENDPOINT + "admin/rd-get-customers",
        {
          page: currentPage,
          nameSearch: nameFilter,
          phone: phoneFilter,
          device: deviceFilter,
          type: subscriptionFilter,
          pin: hubFilter,
        },
        getHeader()
      )
      .then((res) => {
        setLoading(false);
        let d = res.data;
        if (d.success) {
          setCustomers(d.message);
        }
      });
  }
  function getTotalCustomers() {
    axios
      .post(
        API_ENDPOINT + "admin/rd-get-customers-count",
        {
          nameSearch: nameFilter,
          phone: phoneFilter,
          device: deviceFilter,
          type: subscriptionFilter,
          pin: hubFilter,
        },
        getHeader()
      )
      .then((res) => {
        let d = res.data;
        if (d.success) {
          setTotalCount(d.message);
        }
      });
  }

  const pageChange = (e, page) => {
    setCurrentPage(page);
  };

  const onSearchQuery = (e) => {
    setNameFilter(e.target.value);
  };
  const onSearchPhone = (e) => {
    let vl = e.target.value;
    if (vl.length === 12) {
      setPhoneFilter(vl);
    } else if (vl.length === 1) {
      setPhoneFilter("");
    }
    //setSp(e.target.value)
  };
  const searchNow = () => {
    console.log(nameFilter);
  };
  const searchPhoneNow = () => {
    //setPhoneFilter(sp)
  };

  const approvedStatus = (cs) => {
    return cs.approved ? (cs.approved === true ? "Yes" : "No") : "No";
  };
  const approveUser = (user) => {
    setLoading(true);
    const status = !(user.approved === true);
    axios
      .post(
        API_ENDPOINT + "admin/approve-user",
        { silkcart_id: user.silkcart_id, status: status },
        getHeader()
      )
      .then((res) => {
        let d = res.data;
        setLoading(false);
        if (d.success) {
          user.approved = status;
          updateCustomer(user.silkcart_id, user);
          toast("Updated Successfully");
        }
      });
  };
  return (
    <div className="customers-container">
      <CustomCircularP show={loading} />
      <div className="filter-container">
        <div style={{ alignSelf: "center", flex: 0.7 }}>
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-simple-select-label">User Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={subscriptionFilter}
              label="Subscription"
              onChange={(e) => setSubscriptionFilter(e.target.value)}
            >
              <MenuItem value="">ALL</MenuItem>
              <MenuItem value="asr">ASR</MenuItem>
              <MenuItem value="ssp">SSP</MenuItem>
              <MenuItem value="crc">CRC</MenuItem>
              <MenuItem value="swr">SWR</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div style={{ alignSelf: "center", marginLeft: "16px" }}>
          <SearchBar
            placeholder="Search Customer"
            onSearch={onSearchQuery}
            searchNow={searchNow}
          />
        </div>
        <div style={{ alignSelf: "center", marginLeft: "16px" }}>
          <SearchBar
            placeholder="Search Phone"
            onSearch={onSearchPhone}
            searchNow={searchPhoneNow}
          />
        </div>
        <div style={{ flex: 1 }}></div>
        <div style={{ alignSelf: "center" }}>
          <Pagination
            page={currentPage}
            onChange={pageChange}
            count={Math.ceil(totalCount / PAGE_SIZE)}
          />
        </div>
      </div>
      <div
        style={{ fontSize: "12px", fontStyle: "italic", marginBottom: "2px" }}
      >
        Total Customers: <b>{totalCount}</b>
      </div>
      <div className="rd-table-container">
        <table>
          <colgroup>
            <col span="1" />
            <col span="1" style={{ width: "25%" }} />
            <col span="1" style={{ width: "15%" }} />
            <col span="1" style={{ width: "20%" }} />
            <col span="1" style={{ width: "20%" }} />
            <col span="1" style={{ width: "5%" }} />
            <col span="1" style={{ width: "5%" }} />
            <col span="1" style={{ width: "20%" }} />
            <col span="1" style={{ width: "10%" }} />
          </colgroup>
          <tbody>
            <tr>
              <th>SI.no.</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Bank Details</th>
              <th>Address</th>
              <th>Approved?</th>
              <th>User Type</th>
              <th>Joined On</th>
              <th>Action</th>
            </tr>
            {customers.map((p, i) => (
              <tr key={i}>
                <td>{(currentPage - 1) * PAGE_SIZE + i + 1}</td>
                <td>
                  <p
                    style={{ color: "#333", fontSize: 19, fontWeight: "bold" }}
                  >
                    <Link to={`${p.silkcart_id}`}>
                      {/* <IconButton size="small">
                        <PreviewIcon />{" "}
                      </IconButton> */}
                      {p.name}
                    </Link>
                  </p>
                  <p
                    style={{
                      color: "#666",
                      fontSize: "0.8em",
                      marginTop: "0.2em",
                    }}
                  >
                    SID: {p.silkcart_id}
                  </p>
                  <p
                    style={{
                      color: "#666",
                      fontSize: "0.8em",
                      marginTop: "0.2em",
                    }}
                  >
                    LN: {p.licence_number}
                  </p>
                </td>

                <td>{p.phone}</td>
                <td>
                  <b>{p.bank.name}</b>{" "}
                  {`A/C: ${p.bank.account_no} Type: ${p.bank.account_type}`}
                </td>
                <td>
                  {p.area +
                    ", " +
                    p.taluk +
                    ", " +
                    p.district +
                    ", " +
                    p.state +
                    ", Pin: " +
                    p.pin}
                </td>
                <td>{approvedStatus(p)}</td>
                <td>{p.user_type.toUpperCase()}</td>
                <td>{getDateFormat(new Date(p.createdOn))}</td>
                <td>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => approveUser(p)}
                  >
                    {p.approved === true ? "DisApprove" : "Approve"}{" "}
                  </Button>{" "}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
