import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import useCommonGetInit from "../hooks/useCommonGetInit";
import { API_ENDPOINT, REJECTED } from "../../constants/constants";
import { Box, Button, CardMedia, Grid } from "@mui/material";
import BackBtn from "../widgets/BackBtn";
import MuPb from "../widgets/MuPb";
import {
  Card,
  CardContent,
  Typography,
  Link,
  CircularProgress,
} from "@mui/material";
import FullImageModal from "../widgets/FullImageModal";
import ApproveModal from "./ApproveModal";
import { getHeader, toastError } from "../../utils/util";
import axios from "axios";
import { toast } from "react-toastify";
import SimpleYesNoDialog from "../widgets/SimpleYesNoDialog";
import { LoadingButton } from "@mui/lab";

const TransactionDetails = () => {
  const { id } = useParams();
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${API_ENDPOINT}admin/get-payment-receipt?id=${id}`
  );
  if (isLoading) {
    return <MuPb />;
  }
  return (
    <Box>
      <BackBtn />
      <OrderDetails details={data} />
    </Box>
  );
};
const OrderDetails = ({ details }) => {
  const [selectedImage, setSelectedImage] = useState("");
  const [imgOpen, setImgOpen] = useState(false);
  const [approveOpen, setApproveOpen] = useState(false);
  const [cnfOpen, setCnfOpen] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  const handleImageOpen = (url) => {
    setSelectedImage(url);
    setImgOpen(true);
  };
  const handleApproveClose = () => {
    setApproveOpen(false);
  };
  const onYesDialog = async () => {
    setCnfOpen(false);
    try {
      setRejecting(true);
      try {
        const { data } = await axios.get(
          `${API_ENDPOINT}admin/reject-receipt?id=${details._id}`,
          getHeader()
        );
        toast.success(data.message);
        setTimeout(() => {
          document.location.reload();
        }, 200);
      } catch (error) {
        toast.error(error.message);
      }
    } catch (error) {
      toastError(error);
    } finally {
      setRejecting(false);
    }
  };

  const handleImageClose = () => {
    setImgOpen(false);
  };
  const onApprove = () => {
    setApproveOpen(true);
  };
  const onReject = () => {
    setCnfOpen(true);
  };
  const onUpdated = () => {
    setTimeout(() => {
      document.location.reload();
    }, 100);
  };

  return (
    <Card sx={{ maxWidth: 900, margin: "auto", mt: 1, p: 1 }}>
      <SimpleYesNoDialog
        title={"Please confirm!"}
        message={"Are you sure you want to reject this Receipt?"}
        show={cnfOpen}
        setShow={setCnfOpen}
        onYes={onYesDialog}
      />
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Transaction Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                <strong>Record ID:</strong> {details._id}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                <strong>Order ID:</strong>{" "}
                <NavLink to={`/home/orders/order/${details.order_id}`}>
                  {details.order_id}
                </NavLink>
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                <strong>User ID:</strong>{" "}
                <NavLink to={`/home/orders/user/${details.user_id}`}>
                  {details.user_id}
                </NavLink>
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                <strong>Supplier ID:</strong>{" "}
                <NavLink to={`/home/orders/user/${details.supplier_id}`}>
                  {details.supplier_id}
                </NavLink>
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                <strong>Other Receipts:</strong>{" "}
                <NavLink
                  to={`/home/orders/receipt/${details.order_id}?rid=${details._id}`}
                >
                  click here
                </NavLink>
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                <strong>Type:</strong> {details.type}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                <strong>Status:</strong> {details.status}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                <strong>Created On:</strong>{" "}
                {new Date(details.createdOn).toLocaleString()}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                <strong>Updated On:</strong>{" "}
                {new Date(details.updatedOn).toLocaleString()}
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <LoadingButton
                variant="contained"
                color="secondary"
                size="small"
                disabled={details.status !== "pending"}
                loading={rejecting}
                onClick={() => onReject()}
              >
                Reject
              </LoadingButton>
              <LoadingButton
                size="small"
                variant="contained"
                color="primary"
                disabled={details.status !== "pending"}
                onClick={onApprove}
              >
                Approve
              </LoadingButton>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <CardMedia
              component="img"
              image={details.receipt_url}
              alt="Receipt"
              sx={{
                width: "100%",
                height: "auto",
                cursor: "pointer",
              }}
              onClick={() => handleImageOpen(details.receipt_url)}
            />
          </Grid>
        </Grid>
      </CardContent>
      <FullImageModal
        open={imgOpen}
        handleClose={handleImageClose}
        imgUrl={selectedImage}
      />
      <ApproveModal
        open={approveOpen}
        handleClose={handleApproveClose}
        transaction={details}
        onUpdated={onUpdated}
      />
    </Card>
  );
};
export default TransactionDetails;
