import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Chart } from "react-google-charts";
import { styled } from "@mui/system";
import { API_ENDPOINT } from "../../constants/constants";
import axios from "axios";
import { getHeader } from "../../utils/util";
import { Link } from "react-router-dom";
import useCommonGetInit from "../hooks/useCommonGetInit";

const StyledContainer = styled(Container)({
  paddingTop: "32px",
  paddingBottom: "32px",
});

const colors = {
  bg_home: "#F6F5F3",
  card_bg_1: "#F4D9C7",
  card_bg_2: "#d2c2ac",
  card_bg_3: "#F8D3BB",
  card_bg_4: "#fca893",
  text_color_dark: "#535252",
  text_color_normal: "#636363",
  // Add new colors here
  card_bg_5: "#E6E6E6",
  card_bg_6: "#C0C0C0",
};

const StyledCard = styled(Card)(({ cardColor }) => ({
  backgroundColor: colors[cardColor],
  height: "100%",
  display: "flex",
  flexDirection: "column",
  cursor: "pointer", // Add cursor style
  "&:hover": {
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Example hover effect
  },
}));

const StyledCardContent = styled(CardContent)({
  flexGrow: 1,
});

const Dashboard = () => {
  const [totalUsers, setTotalUsers] = React.useState("...");
  const {
    data: ordersData,
    isLoading: ordersLoading,
    isError: isOrderError,
    error: orderError,
  } = useCommonGetInit(`${API_ENDPOINT}admin/get-orders-count`);

  React.useEffect(() => {
    getTotalCustomers();
  }, []);

  function getTotalCustomers() {
    axios
      .post(API_ENDPOINT + "admin/rd-get-customers-count", {}, getHeader())
      .then((res) => {
        let d = res.data;
        if (d.success) {
          setTotalUsers(d.message);
        }
      });
  }
  // Dummy Data
  const todayOrders = 50;
  const totalTransactions = 150;

  return (
    <StyledContainer maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <Link to="/home/orders" style={{ textDecoration: "none" }}>
            <StyledCard cardColor="card_bg_1">
              <StyledCardContent>
                <Typography
                  variant="h6"
                  gutterBottom
                  color={colors.text_color_dark}
                >
                  Today's Orders
                </Typography>
                <Typography variant="h4" color={colors.text_color_normal}>
                  0
                </Typography>
              </StyledCardContent>
            </StyledCard>
          </Link>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Link to="/home/orders" style={{ textDecoration: "none" }}>
            <StyledCard cardColor="card_bg_2">
              <StyledCardContent>
                <Typography variant="h6" gutterBottom>
                  Total Orders
                </Typography>
                <Typography variant="h4">
                  {ordersData ? ordersData.totalItems : "..."}
                </Typography>
              </StyledCardContent>
            </StyledCard>
          </Link>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Link to="/home/users" style={{ textDecoration: "none" }}>
            <StyledCard cardColor="card_bg_3">
              <StyledCardContent>
                <Typography variant="h6" gutterBottom>
                  Total Users
                </Typography>
                <Typography variant="h4">{totalUsers}</Typography>
              </StyledCardContent>
            </StyledCard>
          </Link>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Link to="/home/transactions" style={{ textDecoration: "none" }}>
            <StyledCard cardColor="card_bg_4">
              <StyledCardContent>
                <Typography variant="h6" gutterBottom>
                  Total Transactions
                </Typography>
                <Typography variant="h4">{0}</Typography>
              </StyledCardContent>
            </StyledCard>
          </Link>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledCard cardColor="card_bg_5">
            <StyledCardContent>
              <Chart
                width={"100%"}
                height={"400px"}
                chartType="ColumnChart"
                loader={<div>Loading Chart</div>}
                data={[
                  ["Month", "Orders"],
                  ["Jan", 31],
                  ["Feb", 28],
                  ["Mar", 31],
                  ["Apr", 30],
                  //... other months
                ]}
                options={{
                  title: "Total Orders vs Months",
                  chartArea: { width: "70%" },
                  hAxis: {
                    title: "Month",
                    minValue: 0,
                  },
                  vAxis: {
                    title: "Orders",
                  },
                }}
              />
            </StyledCardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledCard cardColor="card_bg_6">
            <StyledCardContent>
              <Chart
                width={"100%"}
                height={"400px"}
                chartType="ColumnChart"
                loader={<div>Loading Chart</div>}
                data={[
                  ["Month", "Payments"],
                  ["Jan", 4000],
                  ["Feb", 3700],
                  ["Mar", 4500],
                  ["Apr", 3200],
                  //... other months
                ]}
                options={{
                  title: "Total Payments vs Months",
                  chartArea: { width: "70%" },
                  hAxis: {
                    title: "Month",
                    minValue: 0,
                  },
                  vAxis: {
                    title: "Payments",
                  },
                }}
              />
            </StyledCardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default Dashboard;
