import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { API_ENDPOINT } from "../../constants/constants";
import { getHeader, getNewHeader } from "../../utils/util";

const CocoonStock = () => {
  const [cocoonStocks, setCocoonStocks] = useState([]);

  useEffect(() => {
    // Fetch cocoon stocks from the API
    const fetchCocoonStocks = async () => {
      try {
        const response = await axios.get(
          API_ENDPOINT + "admin/get-cocoon-stocks",
          getNewHeader()
        );
        setCocoonStocks(response.data);
      } catch (error) {
        console.error("Error fetching cocoon stocks:", error);
        // Handle the error appropriately
      }
    };

    fetchCocoonStocks();
  }, []);

  return (
    <TableContainer component={Paper}>
      <Typography variant="h4" gutterBottom component="div" sx={{ p: 2 }}>
        Cocoon Stocks
      </Typography>
      <Table sx={{ minWidth: 650 }} aria-label="cocoon stocks table">
        <TableHead>
          <TableRow>
            <TableCell>User Details</TableCell>
            <TableCell align="right">Cocoon Stock</TableCell>
            <TableCell align="right">Expected Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cocoonStocks.map((stock) => (
            <TableRow key={stock._id}>
              <TableCell component="th" scope="row">
                {stock.silkcart_user.length > 0
                  ? stock.silkcart_user[0].name
                  : "N/A"}
                ({stock.silkcart_id})
              </TableCell>
              <TableCell align="right">{stock.cocoon_stock}</TableCell>
              <TableCell align="right">{stock.expected_price}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CocoonStock;
