import "./login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRef, useState } from "react";
import axios from "axios";
import { API_ENDPOINT } from "../constants/constants";
import { saveToken } from "../utils/util";
import loginOfficeImg from "./login-office.jpeg";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import LoginIcon from "@mui/icons-material/Login";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(API_ENDPOINT + "admin/login-1", {
        username,
        password,
      });
      const token = response.data.token;
      if (token) {
        console.log("Token:", token); // Log the token, or handle it as needed
        saveToken(token); // Save the token (Implement this function as per your need)
        navigate("/home"); // Navigate to the home page
      } else {
        // Handle the case when the token is not present in the response
        toast.error("Login failed. Invalid credentials.");
      }
    } catch (error) {
      console.error("Login error:", error);
      // Error handling based on the error response structure
      toast.error(
        error.response?.data?.message || "Login failed. Please try again later."
      );
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div className="container">
      <div className="login-content">
        <div className="login-banner"></div>
        <div className="login-action-area">
          <h1 style={{ textAlign: "center" }}>Welcome Admin</h1>
          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <form onSubmit={onSubmit}>
              <TextField
                margin="normal"
                required
                value={username}
                type="text"
                onChange={(event) => setUsername(event.target.value)}
                variant="outlined"
                fullWidth
                size="small"
                label="Username"
              />

              <FormControl
                required
                margin="normal"
                fullWidth
                size="small"
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              <div style={{ marginTop: "20px" }}>
                <LoadingButton
                  startIcon={<LoginIcon />}
                  type="submit"
                  variant="contained"
                  fullWidth
                >
                  Login
                </LoadingButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
