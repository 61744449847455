import { IconButton, InputBase, Paper } from "@mui/material"
import SearchIcon from '@mui/icons-material/Search';
const SearchBar = ({placeholder, onSearch, size="10px", searchNow})=>{
    return(
        <div>
             <Paper
                    sx={{ p: '0 2px', borderRadius: 22, backgroundColor: '#f4f4f4', display: 'flex', alignItems: 'center', width: '100%' }}
                    >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder={placeholder}
                        onChange={onSearch}
                        inputProps={{ 'aria-label': placeholder }}
                    />
                    <IconButton onClick={searchNow} sx={{ p: size}} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                </Paper>
        </div>
    ) 
}
export default SearchBar