import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CardMedia,
  Pagination,
  Alert,
} from "@mui/material";
import useCommonGetInit from "../hooks/useCommonGetInit";
import { API_ENDPOINT, PAGE_SIZE } from "../../constants/constants";
import { Link, NavLink } from "react-router-dom";
import FullImageModal from "../widgets/FullImageModal";
import MuPb from "../widgets/MuPb";

const SettlementList = ({ type }) => {
  const [selectedImage, setSelectedImage] = useState("");
  const [imgOpen, setImgOpen] = useState(false);
  const {
    data: receiptsData,
    isLoading: receiptsLoading,
    isError: isReceiptError,
    error: receiptError,
  } = useCommonGetInit(`${API_ENDPOINT}admin/get-settlement-list?type=${type}`);
  const handleImageOpen = (url) => {
    setSelectedImage(url);
    setImgOpen(true);
  };
  const handleImageClose = () => {
    setImgOpen(false);
  };
  if (receiptsLoading) {
    return <MuPb />;
  }
  if (receiptError) {
    <Alert severity="error">Error: ${receiptError.message}</Alert>;
  }
  return (
    <Box sx={{ mt: 2 }}>
      <TableContainer component={Paper}>
        <Typography variant="h6" component="div" sx={{ padding: 2 }}>
          Users List
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Si no.</TableCell>
              <TableCell>Silkcart ID</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {receiptsData?.map((item, i) => (
              <TableRow key={item._id}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>
                  {" "}
                  <Link to={`/home/orders/user/${item._id}`}>{item._id}</Link>
                </TableCell>
                <TableCell>₹{item.totalAmount}/-</TableCell>
                <TableCell>
                  {" "}
                  <Link
                    to={`/home/transactions/settle?id=${item._id}&amount=${item.totalAmount}`}
                  >
                    Settle Now
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <FullImageModal
        open={imgOpen}
        handleClose={handleImageClose}
        imgUrl={selectedImage}
      />
    </Box>
  );
};

export default SettlementList;
