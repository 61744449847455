import { toast } from "react-toastify";
import { INR } from "../constants/constants";
import { useLocation } from "react-router-dom";

const APP_TOKEN = "app_token";
const APP_USER = "app_user";
const removeDuplicatesStringArray = (arr) => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
};
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
const saveToken = (token) => {
  localStorage.setItem(APP_TOKEN, token);
};
const getToken = () => {
  return localStorage.getItem(APP_TOKEN);
};
const deleteToken = () => {
  localStorage.removeItem(APP_TOKEN);
};
const isLoggedIn = () => {
  return localStorage.getItem(APP_TOKEN) !== null;
};

const saveUser = (user) => {
  localStorage.setItem(APP_USER, JSON.stringify(user));
};
const getUserName = () => {
  let user = localStorage.getItem(APP_USER);
  let userO = JSON.parse(user);
  return userO ? userO.name : null;
};
const getHeader = () => {
  return {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
};
const getMultipartHeader = () => {
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${getToken()}`,
    },
  };
};
const getNewHeader = () => {
  return {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
};
const getUserEmail = () => {
  let user = localStorage.getItem(APP_USER);
  let userO = JSON.parse(user);
  return userO ? userO.email : null;
};

const shuffleFisherYates = (array) => {
  let i = array.length;
  while (i--) {
    const ri = (Math.floor(Math.random() * i)[(array[i], array[ri])] = [
      array[ri],
      array[i],
    ]);
  }
  return array;
};
const cleanO = (str) => {
  if (str === null) return "";
  if (typeof str !== "string") str = str.toString();
  return str;
};
const activeArranger = (tcs) => {
  tcs.sort((a, b) => {
    let fa = a.category.toLowerCase(),
      fb = b.category.toLowerCase();
    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
  let actives = tcs.filter((t) => t.status === "Active");
  let inactives = tcs.filter((t) => t.status === "Inactive");
  let ars = actives.concat(inactives);
  return ars;
};
const activeTopicArranger = (tcs) => {
  tcs.sort((a, b) => {
    let fa = a.topic.toLowerCase(),
      fb = b.topic.toLowerCase();
    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
  let actives = tcs.filter((t) => t.status === "Active");
  let inactives = tcs.filter((t) => t.status === "Inactive");
  let ars = actives.concat(inactives);
  return ars;
};
const isReallyNumber = (data) => {
  let regex = /^[0-9]+$/;
  return !!data.match(regex);
};
const makeUnique = (array = [], keys = []) => {
  if (!keys.length || !array.length) return [];

  return array.reduce((list, item) => {
    const hasItem = list.find((listItem) =>
      keys.every((key) => listItem[key] === item[key])
    );
    if (!hasItem) list.push(item);
    return list;
  }, []);
};
const makePrice = (product) => {
  let pA = [];
  product.price.map((pO) => {
    let price = pO.price;
    let quantity = pO.quantity;
    let unit = pO.unit;
    let weight = pO.weight;
    let showPrice = pO.showPrice;
    let weight_str = pO.weight === "" ? "" : "(" + pO.weight + ")";
    let price_str = `${showPrice ? INR + price + "/-" : ""}`;
    let quantity_str = `${pO.quantity + pO.unit + weight_str}`;
    pA.push(price_str + quantity_str);
  });
  return pA;
};
const getCategoryName = (cats, id) => {
  let cat = cats.find((ct) => ct._id === id);
  if (cat === undefined) {
    return "?";
  } else {
    return cat.category;
  }
};
export const getDateFormat = (d) => {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let y = d.getFullYear();
  let dd = "0" + d.getDate();
  let h = "0" + d.getHours();
  let min = "0" + d.getMinutes();
  return (
    dd.slice(-2) +
    " " +
    months[d.getMonth()] +
    " " +
    y +
    ", " +
    h.slice(-2) +
    ":" +
    min.slice(-2)
  );
};
const subtractDaysFromDate = (inputDate, daysToSubtract) => {
  // Create a new Date object based on the input date
  const date = new Date(inputDate);

  // Subtract the specified number of days
  date.setDate(date.getDate() - daysToSubtract);

  // Extract day, month, and year components
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-based
  const year = date.getFullYear();

  // Format the resulting date as "dd/mm/yyyy"
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
};
function toastError(error) {
  toast.error(getErrorResponse(error));
}
function getErrorResponse(error) {
  if (error.response && error.response.data && error.response.data.message) {
    return error.response.data.message;
  } else {
    return "An error occurred. Please try again.";
  }
}
function useParamsQuery() {
  return new URLSearchParams(useLocation().search);
}
export const formatCurrency = (amount, locale = "en-IN", currency = "INR") => {
  return new Intl.NumberFormat(locale, { style: "currency", currency }).format(
    amount
  );
};
function checkFileType(url) {
  // Remove any query parameters or hash fragments
  const cleanUrl = url.split("?")[0].split("#")[0];

  // Extract the file extension
  const extension = cleanUrl.split(".").pop().toLowerCase();

  // Check the file type
  if (extension === "png") {
    return "image";
  } else if (extension === "pdf") {
    return "pdf";
  } else {
    return "unknown";
  }
}

export {
  getErrorResponse,
  checkFileType,
  useParamsQuery,
  getMultipartHeader,
  toastError,
  getNewHeader,
  subtractDaysFromDate,
  shuffleFisherYates,
  getCategoryName,
  makePrice,
  cleanO,
  activeArranger,
  activeTopicArranger,
  isReallyNumber,
  makeUnique,
  removeDuplicatesStringArray,
  getHeader,
  capitalizeFirstLetter,
  saveToken,
  getToken,
  deleteToken,
  isLoggedIn,
  saveUser,
  getUserName,
  getUserEmail,
};
