import React, { useEffect, useState } from "react";
import { getHeader } from "../../utils/util";
import {
  ADVANCE_PAID,
  API_ENDPOINT,
  PAYMENT_COMPLETE,
} from "../../constants/constants";
import {
  Modal,
  Backdrop,
  Box,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { toast } from "react-toastify";

const ApproveModal = ({ open, handleClose, transaction, onUpdated }) => {
  useEffect(() => {
    setAmount("");
    setTid("");
  }, [open]);
  const [amount, setAmount] = useState("");
  const [tid, setTid] = useState("");
  const [fullUpdating, setFullUpdating] = useState(false);
  const [advUpdating, setAdvUpdating] = useState(false);
  const onUpdate = async (status) => {
    if (amount.trim() === "") {
      toast.error("Please enter amount!");
      return;
    } else if (isNaN(amount)) {
      toast.error("Please enter a valid number for the amount!");
      return;
    } else if (tid.trim() === "") {
      toast.error("Please enter transaction id!");
      return;
    }
    try {
      updateLoading(status, true);
      const toUpdate = {
        id: transaction._id,
        order_id: transaction.order_id,
        transaction_id: tid,
        status,
        amount,
      };
      const { data } = await axios.post(
        `${API_ENDPOINT}admin/update-receipt-status`,
        toUpdate,
        getHeader()
      );
      onUpdated();
      toast.success(data.message);
    } catch (error) {
    } finally {
      updateLoading(status, false);
    }
  };
  function updateLoading(status, vl) {
    if (status === ADVANCE_PAID) {
      setAdvUpdating(vl);
    } else if (status === PAYMENT_COMPLETE) {
      setFullUpdating(vl);
    }
  }

  return (
    <Modal
      open={open}
      closeAfterTransition
      onClose={handleClose}
      aria-labelledby="payment-modal-title"
      aria-describedby="payment-modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Backdrop open={open} timeout={500}>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            maxWidth: "600px", // Increased max-width for a bit more space
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2, // Added rounded corners
          }}
        >
          <Typography
            id="payment-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 3 }}
          >
            Payment Details
          </Typography>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              fullWidth
              label="Amount"
              variant="outlined"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              sx={{ mb: 2 }} // Increased margin between fields
            />
            <TextField
              fullWidth
              label="Transaction ID"
              variant="outlined"
              value={tid}
              type="text"
              onChange={(e) => setTid(e.target.value)}
              sx={{ mb: 3 }} // Increased margin for more space before the buttons
            />
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr", // Make buttons stack vertically
                gap: 2, // Added gap between buttons
              }}
            >
              <LoadingButton
                variant="contained"
                loading={advUpdating}
                disabled={transaction.status === PAYMENT_COMPLETE}
                color="primary"
                onClick={() => {
                  onUpdate(ADVANCE_PAID);
                }}
                sx={{ gridColumn: "1 / -1" }}
              >
                Mark as Advance Payment
              </LoadingButton>
              <LoadingButton
                disabled={transaction.status === PAYMENT_COMPLETE}
                variant="contained"
                loading={fullUpdating}
                color="secondary"
                onClick={() => {
                  onUpdate(PAYMENT_COMPLETE);
                }}
                sx={{ gridColumn: "1 / -1" }}
              >
                Mark as Full Payment
              </LoadingButton>
            </Box>
          </Box>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Backdrop>
    </Modal>
  );
};

export default ApproveModal;
