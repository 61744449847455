import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import {
  Badge,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from "@mui/material";
import TopMenuIcon from "../widgets/topMenuIcon";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import "./header.css";
import { useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import LockResetIcon from "@mui/icons-material/LockReset";
import {
  API_ENDPOINT,
  MOBILE_THRESHOLD,
  ORDER,
  PAYMENT,
  USER,
} from "../../constants/constants";
import useCommonGetInit from "../hooks/useCommonGetInit";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const { collapse, windowSize, setCollapse, title, logout } = props;
  const navigate = useNavigate();
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${API_ENDPOINT}admin/get-admin-notifications`
  );

  let mtitle = title ? title : "Home";
  let temp = mtitle.replaceAll("-", " ");
  let mytitle = capitalize(temp);

  function capitalize(str) {
    const words = str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase()
    );
    return words;
  }

  const [anchorElAccount, setAnchorElAccount] = useState(null);
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);

  const handleAccountClick = (event) => {
    setAnchorElAccount(event.currentTarget);
  };

  const handleAccountClose = () => {
    setAnchorElAccount(null);
  };

  const handleNotificationsClick = (event) => {
    setAnchorElNotifications(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setAnchorElNotifications(null);
  };

  const openAccount = Boolean(anchorElAccount);
  const openNotifications = Boolean(anchorElNotifications);
  const idAccount = openAccount ? "simple-popover-account" : undefined;
  const idNotifications = openNotifications
    ? "simple-popover-notifications"
    : undefined;

  const goToReset = () => {
    document.location.href = "/reset-password";
  };

  return (
    <div
      className="dashboard_header"
      style={{
        paddingLeft: collapse
          ? 70
          : windowSize.innerWidth < MOBILE_THRESHOLD
          ? 70
          : 248,
      }}
    >
      <TopMenuIcon collapse={collapse} setCollapse={setCollapse} />
      <div
        style={{
          flex: 1,
          paddingLeft: 12,
          alignSelf: "center",
          fontSize: 20,
          fontWeight: 600,
        }}
      >
        {mytitle}
      </div>
      <div
        style={{
          width: 48,
          height: 48,
          alignSelf: "center",
          borderRadius: "50%",
          marginRight: 12,
        }}
      >
        <IconButton
          aria-label="notifications"
          size="large"
          onClick={handleNotificationsClick}
        >
          <Badge badgeContent={data?.data?.length ?? 0} color="secondary">
            <NotificationsNoneIcon />
          </Badge>
        </IconButton>
      </div>
      <div
        style={{
          width: 48,
          height: 48,
          alignSelf: "center",
          borderRadius: "50%",
          marginRight: 12,
        }}
      >
        <IconButton
          aria-describedby={idAccount}
          onClick={handleAccountClick}
          aria-label="account"
          size="large"
        >
          <ManageAccountsIcon sx={{ color: "rgb(156, 39, 176)" }} />
        </IconButton>
      </div>
      <Popover
        id={idAccount}
        open={openAccount}
        anchorEl={anchorElAccount}
        onClose={handleAccountClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuList>
          <MenuItem>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Settings</ListItemText>
          </MenuItem>
          <MenuItem onClick={goToReset}>
            <ListItemIcon>
              <LockResetIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Reset Password</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </MenuList>
      </Popover>
      <Popover
        id={idNotifications}
        open={openNotifications}
        anchorEl={anchorElNotifications}
        onClose={handleNotificationsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuList>
          {data?.data?.length > 0 ? (
            <>
              {data?.data?.map((notification, index) => (
                <MenuItem
                  key={index}
                  style={{ display: "block", maxWidth: "300px" }}
                  onClick={() => {
                    setAnchorElNotifications(null);
                    if (notification.category === PAYMENT) {
                      navigate(
                        `/home/transactions/${notification.source_user_id}`
                      );
                    } else if (notification.category === USER) {
                      navigate(`/home/users/${notification.source_user_id}`);
                    } else if (notification.category === ORDER) {
                      navigate(
                        `/home/orders/order/${notification.source_user_id}`
                      );
                    }
                  }}
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    {notification.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                  >
                    {notification.message}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {notification.createdOn}
                  </Typography>
                </MenuItem>
              ))}
              <Divider />
              <MenuItem
                onClick={() => {
                  // Redirect to the notifications page or trigger a modal
                  document.location.href = "#";
                }}
                style={{ justifyContent: "center" }}
              >
                <Typography variant="body2" color="primary">
                  See All
                </Typography>
              </MenuItem>
            </>
          ) : (
            <MenuItem>
              <ListItemText>No Notifications</ListItemText>
            </MenuItem>
          )}
        </MenuList>
      </Popover>
    </div>
  );
};

export default Header;
