import React from "react";
import { Box, Divider, Typography } from "@mui/material";

const CocoonDetails = ({ order }) => {
  if (!order.hasOwnProperty("cocoonSpecifics")) {
    return <Box>No Cocoon Details Available yet</Box>;
  }

  const {
    fc1_cocoons_kg,
    fc1_melted_cocoons_kg,
    fc1_pupation_percent,
    fc1_total_cocoons,
    fc1_total_cocoons_kg,
    fc2_cocoons_kg,
    fc2_melted_cocoons_kg,
    fc2_pupation_percent,
    fc2_total_cocoons,
    fc2_total_cocoons_kg,
    fitForSeed,
    price,
    status,
    offerer,
  } = order.cocoonSpecifics;

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#f7f7f7",
        borderRadius: "8px",
        margin: "20px",
      }}
    >
      <Detail label="FC1 Cocoons Kg" value={fc1_cocoons_kg} />
      <Detail label="FC1 Melted Cocoons Kg" value={fc1_melted_cocoons_kg} />

      <Detail label="FC1 Pupation Percent" value={fc1_pupation_percent} />

      <Detail label="FC1 Total Cocoons" value={fc1_total_cocoons} />

      <Detail label="FC1 Total Cocoons Kg" value={fc1_total_cocoons_kg} />
      <Divider />
      <Detail label="FC2 Cocoons Kg" value={fc2_cocoons_kg} />

      <Detail label="FC2 Melted Cocoons Kg" value={fc2_melted_cocoons_kg} />

      <Detail label="FC2 Pupation Percent" value={fc2_pupation_percent} />

      <Detail label="FC2 Total Cocoons" value={fc2_total_cocoons} />

      <Detail label="FC2 Total Cocoons Kg" value={fc2_total_cocoons_kg} />
      <Divider />
      <Detail label="Fit For Seed" value={fitForSeed} />

      <Detail label="Price" value={price} />

      <Detail label="Status" value={status} />

      <Detail
        label="Payment status"
        value={
          order.cocoon_payment_status ? order.cocoon_payment_status : "Pending"
        }
      />
    </Box>
  );
};

const Detail = ({ label, value }) => (
  <Box
    sx={{ display: "flex", justifyContent: "space-between", marginBottom: 1 }}
  >
    <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#333" }}>
      {label}:
    </Typography>
    <Typography variant="body1" sx={{ color: "#555" }}>
      {value}
    </Typography>
  </Box>
);

export default CocoonDetails;
